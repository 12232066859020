import React, { useState, useEffect } from 'react';
import { Shield, Code, Cloud, FileText } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./Servicios.css";

const WavesSVG = () => (
  <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
    <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g className="parallax">
      <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(249,248,239,0.7)" />
      <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(249,248,239,0.5)" />
      <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(249,248,239,0.3)" />
      <use xlinkHref="#gentle-wave" x="48" y="7" fill="#F9F8EF" />
    </g>
  </svg>
);

const ServiceCard = ({ icon: Icon, title, color }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/servicios'); // Redirige a la página principal de servicios
  };

  return (
    <div className="service-card" style={{ '--card-color': color }} onClick={handleCardClick}>
      <div className="card-content">
        <Icon className="mb-4" size={24} />
        <h2 className="text-xl font-bold mb-2">{title}</h2>
      </div>
      <div className="waves-container">
        <WavesSVG />
      </div>
    </div>
  );
};

const Servicios = () => {
  const [services, setServices] = useState([]);
  
  const colors = {
    'ciberseguridad': '#F71973',
    'fábrica de software': '#2CBFBF',
    'cloud': '#3E2956',
    'consultorías': '#AD326D'
  };

  useEffect(() => {
    const fetchServicios = async () => {
      try {
        const response = await axios.get('https://tekrea-backend-255659019198.us-central1.run.app/api/services');
        const servicesData = response.data.services;

        const mappedServices = servicesData.map((service) => {
          let icon;
          switch (service.title.toLowerCase()) {
            case 'ciberseguridad':
              icon = Shield;
              break;
            case 'fábrica de software':
              icon = Code;
              break;
            case 'cloud':
              icon = Cloud;
              break;
            case 'consultorías':
              icon = FileText;
              break;
            default:
              icon = FileText;
          }
          return { 
            ...service, 
            icon,
            color: colors[service.title.toLowerCase()] || '#AD326D'
          };
        });

        setServices(mappedServices);
      } catch (error) {
        console.error('Error al obtener los servicios', error);
      }
    };

    fetchServicios();
  }, []);

  return (
    <section className="servicios">
      <div className="service-cards">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            icon={service.icon}
            title={service.title}
            color={service.color}
          />
        ))}
      </div>
    </section>
  );
};

export default Servicios;
